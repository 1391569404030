import { AdPosition, isAdPositionLoaded } from '../../types/ads/Ad.interface'

export const checkConfig = (
    config?: AdPosition | { enabled: boolean; message: string } | any,
    renderOutOfThePage?: boolean
) =>
    !!config &&
    isAdPositionLoaded(config) &&
    !!config?.adUnit &&
    !!config?.divId &&
    (!!config?.sizes || config?.outOfPage || renderOutOfThePage)
