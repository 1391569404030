import { toRem } from '../../../helpers/theme'

const style = ({ theme }) => ({
    '& button, a': {
        minHeight: 'unset',
        padding: 0,
        color: `${theme.colors.text.white} !important`,
        backgroundColor: `${theme.colors.background.inverted} !important`,
        width: toRem(40),
        height: toRem(40),
        '& .btn_icon': {
            fontSize: toRem(16)
        }
    }
})

export default style
