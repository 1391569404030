/* eslint-disable @typescript-eslint/naming-convention */

export type WidgetOnlyForBanner = {
    h: number
    i: string
    w: number
    x: number
    y: number
    title: string
    isEdit: boolean
    component: string
    renderer: string
    items?: WidgetOnlyForBanner[]
    extendedAttributes: {
        title: string
        desktop?: SingleAdPositionSource
        mobile?: SingleAdPositionSource
        visible: boolean
        media_visibility?: string
        enable_ads?: boolean
        ads_out_of_page?: boolean
    }
    [key: string]: any
}

export type SingleAdPositionSource = {
    header: string
    dimensions?: string
    body: string
    placeholder_url: string
    placeholder_image: any
    lazy: boolean
    auto_refresh_freq: number
    fixed_height: number
    enabled: boolean // new
    ads_out_of_page: boolean // new
    ads_out_of_page_type: string // needs definition in cms
}
export type AdPositionSource = SingleAdPositionSource & {
    device: 'D' | 'M'
    dimension?: string
    name: string
    isWidget?: boolean
}

export type AdPositionLoaded = {
    adUnit: string
    sizes: [number, number][]
    divId: SlotDivId
    placeholderImage?: string
    placeholderHref?: string
    lazyLoad?: boolean
    autoRefresh?: number
    fixedHeight?: number
    enabled: boolean // new
    outOfPage: boolean // new
    outOfPageType?: string // new
    zoneName: ZoneName
    message?: string
    isWidget?: true
}
export type AdPositionLoading = { enabled: false; message?: string }
export type AdPosition = AdPositionLoaded | AdPositionLoading

export const isAdPositionLoaded = (
    adPosition: AdPosition | { enabled: boolean; message: string }
): adPosition is AdPositionLoaded => {
    return adPosition.enabled
}
export type AdPositionDevice = {
    [key: ZoneName]: AdPositionLoaded
}

export type ProcessedAdList = {
    dfp: AdPositionDevice
    videos?: any
    enabled: boolean
    error?: Error
    message?: string
    id: string
    debug?: any
    type: string
    blockNameList: string[][]
    targetingArguments?: any
    isSitemap: boolean
    isArticle: boolean
    isWidgetLayout: boolean
    isDefault: boolean
}

export type AdList = {
    dfp?: AdPositionDevice
    videos?: any
    enabled?: boolean
    id: string
    type?: string
}

export type DisplaySlotsSettings = {
    targetingArguments?: any
    maxAdWidth?: number | string
    divId: string
    zoneName: string
}

export type MappedVia<TKey extends string | number | symbol, TVal> = {
    [key in TKey]?: TVal
}

export enum ExternalAd {
    INSCREEN = 'inscreen',
    WALLPAPER = 'wallpaper'
}

export type AdsSSRProps = {
    dfp: AdPositionDevice
    videos: any
    enabled: boolean
    error?: Error
    message?: string
    id?: string
    type?: string
    targetingArguments?: any
}

export type AdsContextState = {
    adManifest: AdList & {
        single?: AdPositionDevice
        listing?: AdPositionDevice
    }
    displayZones: MappedVia<ZoneName, DisplaySlotsSettings>
    refreshSlots: string[]
    filledSlots: MappedVia<ZoneName, boolean>
    inarticleAdMounted: boolean
    native: MappedVia<(typeof ExternalAd)[keyof typeof ExternalAd], any>
}

export type SlotDivId = string
export type ZoneName = `${'D' | 'M'} - ${string}`

// eslint-disable-next-line no-undef
export type GoogleTagSlot = googletag.Slot
// eslint-disable-next-line no-undef
export type GoogleTagSlotRenderEvent = googletag.events.SlotRenderEndedEvent

export type NativeGoogleTagIdCallback = (slots: SlotDivId[]) => void
export type NativeGoogleTagSlotCallback = (slots: GoogleTagSlot[]) => void
export type TargetingArguments = Record<string, string | number | string[]>

export type GptSlotDefinition = {
    adUnit: string
    sizes: [number, number][]
    divId: SlotDivId
    lazyLoad?: boolean
    fixedHeight?: number
    outOfPage?: boolean
    disablePlaceholder?: boolean
    disableFixedHeight?: boolean
    zoneName: ZoneName
    targetingArguments?: TargetingArguments
}
export type DefineGptSlotsProps = (slots: GptSlotDefinition[], targetingArguments?: TargetingArguments) => void
export type DefineGptSlotProps = (slots: GptSlotDefinition, targetingArguments?: TargetingArguments) => void
