import { RefObject, useCallback, useEffect, useMemo, useReducer } from 'react'
import { useInView } from 'react-intersection-observer'

import { ContainerState } from '../SlidingWrapper.types'
import { useAnimationFrameCallback } from './useAnimationFrameCallback'

const containerInitialState: ContainerState = {
    position: 'relative',
    left: 0,
    top: 0,
    width: 'auto'
}
const containerStyleReducer = (state: ContainerState, action: Partial<ContainerState>) => ({ ...state, ...action })
const useContainerState = () => useReducer(containerStyleReducer, containerInitialState)
export const useSlidingWrapper = ({
    wrapperRef,
    contentRef,
    offsetTop = 0,
    contentWidth,
    offsetBottom = 0,
    enabled,
    contentHeight
}: {
    wrapperRef: RefObject<HTMLElement>
    contentRef: RefObject<HTMLElement>
    offsetTop?: number | null
    contentWidth?: string | number | null
    offsetBottom?: number | null
    enabled: boolean
    contentHeight?: number | string | null
}) => {
    const getBoundingRects = useCallback(() => {
        const wrapperBounding = wrapperRef?.current?.getBoundingClientRect()
        const contentBounding = contentRef?.current?.getBoundingClientRect()
        return [wrapperBounding, contentBounding] as const
    }, [])
    const { ref, inView } = useInView({
        threshold: [0, 1],
        skip: !enabled
    })
    const [containerStyle, setContainerStyle] = useContainerState()
    useEffect(() => {
        setContainerStyle({ height: contentHeight === null ? undefined : contentHeight })
    }, [contentHeight])
    const setFixed = useCallback(
        (left: number, width: string | number = 'auto') =>
            setContainerStyle({ position: 'fixed', left, top: offsetTop ?? 0, width }),
        [offsetTop]
    )
    const setAbsolute = useCallback(
        (top: number, width: string | number = 'auto') =>
            setContainerStyle({ position: 'absolute', top, left: 0, width }),
        []
    )

    const { isAboveTopEdge, isBelowTopEdge, isAboveBottomEdge, isBelowBottomEdge } = useMemo(
        () => ({
            isAboveTopEdge: (top: number) => top - (offsetTop || 0) >= 0,
            isBelowTopEdge: (top: number) => top - (offsetTop || 0) <= 0,
            isAboveBottomEdge: (top: number, freeSpace: number) => top + freeSpace - (offsetTop || 0) >= 0,
            isBelowBottomEdge: (top: number, freeSpace: number) => -top + (offsetTop || 0) >= freeSpace
        }),
        [offsetTop]
    )

    const handleScroll = useCallback(() => {
        const [wrapperBounding, contentBounding] = getBoundingRects()
        if (!wrapperBounding || !contentBounding) {
            return
        }
        const { height: wrapperHeight, top: wrapperTop, left: wrapperLeft } = wrapperBounding
        const { width, height = 0 } = contentBounding
        if (isAboveTopEdge(wrapperTop)) {
            setAbsolute(0, width)
            return
        }
        const freeSpace = Math.max(0, wrapperHeight - height - (offsetBottom || 0))
        if (isBelowBottomEdge(wrapperTop, freeSpace)) {
            setAbsolute(freeSpace, width)
            return
        }

        if (isAboveBottomEdge(wrapperTop, freeSpace) && isBelowTopEdge(wrapperTop)) {
            setFixed(wrapperLeft, width)
        }
    }, [contentWidth, setAbsolute, setFixed, getBoundingRects, offsetBottom])

    const wrappedScrollHandler = useAnimationFrameCallback(handleScroll)
    useEffect(() => {
        if (!enabled || typeof window === 'undefined' || !inView) {
            return () => {}
        }
        window.addEventListener('resize', wrappedScrollHandler)
        window.addEventListener('scroll', wrappedScrollHandler)
        wrappedScrollHandler()
        return () => {
            window.removeEventListener('scroll', wrappedScrollHandler)
            window.removeEventListener('resize', wrappedScrollHandler)
        }
    }, [enabled, wrappedScrollHandler, inView])

    return { ref, containerStyle }
}
