/* eslint-disable @typescript-eslint/naming-convention */
import { createStyledComponent } from '../../helpers/components/createStyledComponent'
import { toRem } from '../../helpers/theme'
import { ContainerState } from './SlidingWrapper.types'

const styles = ({
    containerStyle,
    contentWidth,
    contentHeight,
    frameHeight,
    hasMarginBottomXs,
    hasMarginBottomMd,
    theme,
    minHeightXs,
    minHeightMd
}: {
    containerStyle?: ContainerState
    contentWidth?: number | string
    contentHeight?: number | string
    frameHeight?: number | string
    hasMarginBottomXs?: boolean
    hasMarginBottomMd?: boolean
    theme?: any
    minHeightXs?: number
    minHeightMd?: number
}) => ({
    position: 'relative',
    width: '100%',
    padding: 0,
    height: (frameHeight && toRem(frameHeight)) || '100%',
    minHeight: (minHeightXs && toRem(minHeightXs)) || 'auto',
    pointerEvents: 'none',
    overflow: 'hidden',
    marginBottom: (hasMarginBottomXs && toRem(30)) || 'initial',
    [theme.breakpoints.up('md')]: {
        minHeight: (minHeightMd && toRem(minHeightMd)) || 'auto',
        marginBottom: (hasMarginBottomMd && toRem(30)) || 'initial'
    },
    '.SlidingWrapper': {
        '&_container': {
            position: 'absolute',
            width: contentWidth ? toRem(contentWidth) : '100%',
            height: contentHeight ? toRem(contentHeight) : 'auto',
            ...(containerStyle || {}),
            pointerEvents: 'all',
            minHeight: (minHeightXs && toRem(minHeightXs)) || 'fit-content',
            [theme.breakpoints.up('md')]: {
                minHeight: (minHeightMd && toRem(minHeightMd)) || 'auto'
            }
        },
        '&_anchor': {
            pointerEvents: 'none'
        }
    }
})

export default createStyledComponent({ styles })
