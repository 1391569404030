/* eslint-disable @typescript-eslint/naming-convention */
import PropTypes, { InferProps } from 'prop-types'
import React, { PropsWithChildren, useRef } from 'react'

import { useIsClient } from '../../hooks/useIsClient'
import { withErrorBoundary } from '../ErrorBoundary/ErrorBoundary.component'
import { useSlidingWrapper } from './hooks/useSlidingWrapper'
import { useSpacingCheck } from './hooks/useSpacingCheck'
import SlidingWrapperStyled from './SlidingWrapper.style'

const propTypes = {
    offsetTop: PropTypes.number,
    offsetBottom: PropTypes.number,
    contentWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    contentHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    frameHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minimumHeightDifference: PropTypes.number,
    enabled: PropTypes.bool,
    renderInSmallSpace: PropTypes.bool,
    minimumFrameHeight: PropTypes.number,
    hasMarginBottomXs: PropTypes.bool,
    hasMarginBottomMd: PropTypes.bool,
    minHeightXs: PropTypes.number,
    minHeightMd: PropTypes.number,
    className: PropTypes.string
}

type SlidingWrapperProps = PropsWithChildren<InferProps<typeof propTypes>>
const SlidingWrapper = ({
    offsetTop,
    offsetBottom,
    children,
    contentWidth,
    contentHeight,
    frameHeight,
    enabled,
    renderInSmallSpace,
    minimumHeightDifference,
    minimumFrameHeight,
    hasMarginBottomXs,
    hasMarginBottomMd,
    minHeightXs,
    minHeightMd,
    className,
    ...rest
}: SlidingWrapperProps) => {
    const contentRef = useRef<HTMLDivElement | null>(null)
    const wrapperRef = useRef<HTMLDivElement | null>(null)

    const isClient = useIsClient()

    const isEnabled = !!isClient && !!enabled

    const [hasEnoughSpace, checkedSpacing] = useSpacingCheck({
        wrapperRef,
        contentRef,
        minimumFrameHeight,
        minimumHeightDifference,
        renderInSmallSpace,
        enabled: isEnabled
    })

    const isScrolling = isEnabled && hasEnoughSpace && checkedSpacing

    const { ref, containerStyle } = useSlidingWrapper({
        wrapperRef,
        contentRef,
        offsetTop,
        contentWidth,
        offsetBottom,
        enabled: isScrolling,
        contentHeight
    })

    if (!children) {
        return null
    }

    return (
        <SlidingWrapperStyled
            className={className ? className : undefined}
            containerStyle={isScrolling ? containerStyle : undefined}
            frameHeight={frameHeight ?? '100%'}
            minHeightXs={minHeightXs ? minHeightXs : undefined}
            minHeightMd={minHeightMd ? minHeightMd : undefined}
            hasMarginBottomXs={!!hasMarginBottomXs}
            hasMarginBottomMd={!!hasMarginBottomMd}
            {...rest}
            ref={(node: HTMLDivElement) => {
                ref(node)
                wrapperRef.current = node
            }}>
            <div
                className="SlidingWrapper_wrapper"
                id="sliding_wrapper"
                style={{ height: frameHeight ?? undefined }}
                ref={wrapperRef}>
                {(hasEnoughSpace || renderInSmallSpace || !checkedSpacing) && (
                    <div className="SlidingWrapper_container" id="sliding_wrapper_container" ref={contentRef}>
                        {children}
                    </div>
                )}
            </div>
        </SlidingWrapperStyled>
    )
}

SlidingWrapper.propTypes = propTypes

SlidingWrapper.defaultProps = {
    offsetBottom: 0,
    offsetTop: 0,
    contentWidth: undefined,
    contentHeight: undefined,
    frameHeight: undefined,
    minimumHeightDifference: 30,
    enabled: true,
    renderInSmallSpace: true,
    minimumFrameHeight: undefined,
    hasMarginBottomXs: false,
    hasMarginBottomMd: false,
    minHeightXs: undefined,
    minHeightMd: undefined,
    className: undefined
}

export default withErrorBoundary(SlidingWrapper, {
    FallbackComponent: () => null,
    onError: (error, componentStack) => {
        // eslint-disable-next-line no-console
        console.error('[SlidingWrapper]: ', error, componentStack)
    }
})
