import { RefObject, useCallback, useEffect, useReducer, useState } from 'react'

const useSwitch = () => useReducer(() => true, false)
export const useSpacingCheck = ({
    wrapperRef,
    contentRef,
    minimumFrameHeight,
    minimumHeightDifference = 0,
    renderInSmallSpace,
    enabled
}: {
    wrapperRef: RefObject<HTMLElement>
    contentRef: RefObject<HTMLElement>
    minimumFrameHeight?: number | null
    minimumHeightDifference?: number | null
    renderInSmallSpace?: boolean | null
    enabled: boolean
}) => {
    const [hasEnoughSpace, setHasEnoughSpace] = useState(false)
    const [checkedSpacing, setCheckedSpacig] = useSwitch()
    const getBoundingRects = useCallback(() => {
        const wrapperBounding = wrapperRef?.current?.getBoundingClientRect()
        const contentBounding = contentRef?.current?.getBoundingClientRect()
        return [wrapperBounding, contentBounding]
    }, [])

    const checkHasEnoughSpace = useCallback(() => {
        const [wrapperBounding, contentBounding] = getBoundingRects()
        const hasMinHeightLimit = typeof minimumFrameHeight === 'number' && minimumFrameHeight > 0

        setCheckedSpacig()

        if (!hasMinHeightLimit) {
            setHasEnoughSpace(true)
            return
        }
        if (!wrapperBounding || wrapperBounding.height < minimumFrameHeight) {
            setHasEnoughSpace(false)
            return
        }
        if (!contentBounding) {
            setHasEnoughSpace(wrapperBounding.height >= minimumFrameHeight)
            return
        }
        setHasEnoughSpace(wrapperBounding.height > contentBounding.height + (minimumHeightDifference || 0))
    }, [minimumHeightDifference, minimumFrameHeight, getBoundingRects, renderInSmallSpace])

    useEffect(() => {
        if (enabled) {
            checkHasEnoughSpace()
        }
    }, [enabled, checkHasEnoughSpace, checkedSpacing])

    return [hasEnoughSpace, checkedSpacing] as const
}
