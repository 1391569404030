const articleInsertAds = {
    every: 3,
    offset: -3,
    afterBlocks: ['core/paragraph', 'core/columns', 'core/embed'],
    elements: [
        {
            disablePlaceholder: true,
            disableFixedHeight: true,
            position: ['In article - 1', 'In article - 1 - alt']
        },
        {
            disablePlaceholder: true,
            disableFixedHeight: true,
            position: ['In article - 2', 'In article - 2 - alt']
        },
        ...Array.from({ length: 4 }).map((_, i) => ({
            disablePlaceholder: true,
            disableFixedHeight: true,
            position: [`In article - repeating${i ? `-${i}` : ''}`, `In article - repeating - alt${i ? `-${i}` : ''}`],
            divId: ['div-gpt-ad-1619709872388-0-rep', 'div-gpt-ad-1619709872388-0-rep-alt']
        }))
    ]
}

const listingInsertAds = {
    every: 2,
    offset: 0,
    elements: [
        {
            position: 'Billboard - 1'
        },
        {
            disablePlaceholder: true,
            disableFixedHeight: true,
            position: 'Billboard - 2'
        },
        {
            disablePlaceholder: true,
            disableFixedHeight: true,
            position: 'Billboard - 3'
        }
    ]
}

const staticAds = {
    billboardTop: {
        disablePlaceholder: true,
        disableFixedHeight: true,
        position: 'Billboard - top'
    },
    billboard1: {
        position: 'Billboard - 1'
    },
    billboard2: {
        disablePlaceholder: true,
        disableFixedHeight: true,
        position: 'Billboard - 2'
    },
    billboard3: {
        disablePlaceholder: true,
        disableFixedHeight: true,
        position: 'Billboard - 3'
    },
    outOfPage1: {
        desktopOnly: true,
        onePxSlot: true,
        renderOutOfPage: true,
        position: ['Wallpaper - left', 'Floating - 1'] // @TODO replace with OOP cms input once available
    },
    outOfPage3: {
        mobileOnly: true,
        onePxSlot: true,
        renderOutOfPage: true,
        position: 'Floating' // @TODO replace with OOP cms input once available
    },
    anchor: {
        // onePxSlot: true,
        renderOutOfPage: true,
        position: 'Hardcoded Anchor'
    }
}
export { staticAds, articleInsertAds, listingInsertAds }
