import { useMemo } from 'react'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import clsx from 'clsx'
import Head from 'next/head'
import { usePathname } from 'next/navigation'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { useBreakpoints } from '@hmn/rtl-web-core/hooks'

import { Graphic, graphicFillVariants, graphicVariants } from '../Decoration/components'
import { ArrowLeftIcon } from '../Icon'
import { Link } from '../Link'
import { PaginationButton } from './components'
import styles from './Pagination.style'

const paginationEnd = (pageTotal, pageChunk, pagesPerChunk, selected) => {
    if (pageTotal <= pageChunk * pagesPerChunk + pagesPerChunk) {
        return pageTotal
    }
    if (pagesPerChunk === 9) {
        return selected > 5 ? selected + 4 : pagesPerChunk
    }
    return selected > 3 ? selected + 3 : pagesPerChunk
}

const paginationStart = (end, pagesPerChunk) => {
    if (end <= pagesPerChunk) {
        return 0
    }
    return end - pagesPerChunk
}

const PaginationStyled = styled.div(props => ({ ...styles(props) }))

/**
 * If action is anything other than the supported actions, it will return the page value unmodified.
 *
 * @param {URLSearchParams} params
 * @param {'increase'|'decrease'} action The action that you want to perform.
 */
function getParamsWithPageModified(params, action) {
    const params1 = new URLSearchParams(params.entries())

    const pageValue = parseInt(params1.get('stranica'), 10)

    const newPageValue = (() => {
        switch (action) {
            case 'increase': {
                return pageValue + 1
            }

            case 'decrease': {
                return pageValue - 1
            }

            default: {
                return pageValue
            }
        }
    })()

    params1.set('stranica', newPageValue)

    return params1
}

function Pagination({ pageTotal: pageTotalInput, selected, paginationColor, isSerp, serpHref, params = [], ...rest }) {
    const [isDesktop] = useBreakpoints('md')
    const uid = useUIDSeed()
    const router = useRouter()

    const pageTotal = Math.ceil(pageTotalInput)
    const pathname = usePathname()

    const urlParams = useMemo(() => new URLSearchParams(params), [params])

    const { nextUrl, prevUrl, basePath } = useMemo(() => {
        // search page has different pagination params structure
        // so applying this fallback for now to not change other codebase
        if (isSerp && serpHref) {
            return {
                basePath: `${serpHref}`,
                prevUrl: selected > 1 ? `${serpHref}${selected - 1}` : null,
                nextUrl: selected < pageTotal ? `${serpHref}${selected + 1}` : null
            }
        }

        const pathParts = (router?.asPath || '').split('/').filter(Boolean)
        if (!pathParts) {
            return {}
        }

        if (pathParts[pathParts.length - 1] === selected.toString()) {
            pathParts.pop()
        }

        const params1 = new URLSearchParams(urlParams.entries())
        params1.set('stranica', selected)

        return {
            basePath: `${pathname}`,
            prevUrl: selected > 1 ? `${pathname}?${getParamsWithPageModified(params1, 'decrease').toString()}` : null,
            nextUrl:
                selected < pageTotal ? `${pathname}?${getParamsWithPageModified(params1, 'increase').toString()}` : null
        }
    }, [router, selected, pageTotal, isSerp, pathname, serpHref, urlParams])

    const onButtonClick = (page, e) => {
        e?.target?.blur?.()
        if (page > pageTotal || page < 1) {
            return false
        }

        return false
    }

    if (!pageTotal || !selected) {
        return null
    }

    const pagesPerChunk = isDesktop ? 9 : 6
    const pageChunk = Math.floor(selected / pagesPerChunk)

    const end = paginationEnd(pageTotal, pageChunk, pagesPerChunk, selected)
    const start = paginationStart(end, pagesPerChunk)

    return (
        <>
            <PaginationStyled paginationColor={paginationColor} {...rest}>
                <Graphic
                    className="pagination_graphic"
                    variant={graphicVariants.ETA}
                    fillVariant={graphicFillVariants.ALTERNATIVE}
                />
                <div className="pagination_wrapper">
                    {selected !== 1 && (
                        <PaginationButton
                            id="pagination_left"
                            icon={ArrowLeftIcon}
                            href={prevUrl}
                            onClick={() => onButtonClick(selected - 1)}
                        />
                    )}

                    <div className="pagination_items">
                        {Array.from({ length: end - start }, (_, index) => {
                            const page = index + start + 1

                            const params1 = new URLSearchParams(urlParams.entries())
                            params1.set('stranica', page)

                            return (
                                <Link
                                    key={uid(`${index + page}`)}
                                    href={`${basePath}?${params1.toString()}`}
                                    className={clsx('pagination_item', page === selected && 'isSelected')}
                                    legacyBehavior>
                                    <span
                                        id="pagination_item"
                                        key={uid(index)}
                                        className={clsx('pagination_item', page === selected && 'isSelected')}
                                        aria-label={page}
                                        role="button"
                                        tabIndex={index}
                                        onKeyUp={e => onButtonClick(page, e)}
                                        onClick={e => onButtonClick(page, e)}>
                                        {page}
                                    </span>
                                </Link>
                            )
                        })}
                    </div>

                    {selected < pageTotal && (
                        <PaginationButton
                            id="pagination_right"
                            icon={ArrowLeftIcon}
                            rotate={180}
                            href={nextUrl}
                            onClick={() => onButtonClick(selected + 1)}
                        />
                    )}
                </div>
            </PaginationStyled>

            {(prevUrl || nextUrl) && (
                <Head>
                    {prevUrl && <link rel="prev" href={prevUrl} />}
                    {nextUrl && <link rel="next" href={nextUrl} />}
                </Head>
            )}
        </>
    )
}

Pagination.propTypes = {
    selected: PropTypes.number,
    pageTotal: PropTypes.number,
    paginationColor: PropTypes.string,
    isSerp: PropTypes.bool,
    serpHref: PropTypes.string,
    params: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
}

Pagination.defaultProps = {
    selected: 1,
    pageTotal: 0,
    paginationColor: undefined,
    isSerp: false,
    serpHref: '',
    params: []
}

export default Pagination
