const backgroundColor = '#6b34ed' // default VOYO ljubičasta
// const backgroundColor = '#fe4c2b' // za Toma placeholder

const style = () => ({
    minHeight: '30px',
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor,

    '.Placeholder_image': {
        width: '100%',
        height: '100%',
        // object-fit: cover;
        objectFit: 'none',
        objectPosition: '50% 50%',
        backgroundColor,
        transform: 'scale(1)'
    }
})

export default style
