import { responsive, toRem, toRems } from '../../helpers/theme'

const style = ({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& .pagination_wrapper': {
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        padding: toRems([0, 12]),
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        background: theme.colors.background.body.default,
        ...responsive([
            { justifyContent: 'space-between' },
            { justifyContent: 'space-between' },
            { justifyContent: 'center' },
            { justifyContent: 'center' },
            { justifyContent: 'center' }
        ])
    },
    '& .pagination_items': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...responsive([
            { padding: toRems([0, 20]) },
            { padding: toRems([0, 20]) },
            { padding: toRems([0, 30]) },
            { padding: toRems([0, 30]) },
            { padding: toRems([0, 30]) }
        ])
    },
    '& .pagination_item': {
        fontWeight: 'bold',
        fontSize: toRem(16),
        cursor: 'pointer',
        color: theme.colors.primary
    },
    '& .pagination_item.isSelected': {
        color: theme.colors.text.accent
    },
    '& .pagination_item+.pagination_item': {
        marginLeft: toRem(16)
    },
    '& .pagination_graphic': {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
})

export default style
