/* eslint-disable @typescript-eslint/naming-convention */
import { createStyledComponent } from '../../../helpers/components/createStyledComponent'
import { toRem } from '../../../helpers/theme'

const styles = ({
    theme,
    inColumn = false,
    maxAdWidth,
    minHeightXs,
    minHeightMd,
    hasMarginBottomXs,
    hasMarginBottomMd
}: {
    theme?: any
    inColumn?: boolean
    maxAdWidth?: number | string
    minHeightXs?: number
    minHeightMd?: number
    hasMarginBottomXs?: boolean
    hasMarginBottomMd?: boolean
}) => ({
    width: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: minHeightXs || 'auto',
    marginBottom: hasMarginBottomXs && toRem(30),
    [theme.breakpoints.up('md')]: {
        minHeight: minHeightMd || 'auto',
        marginBottom: hasMarginBottomMd && toRem(30)
    },
    '& > *': {
        flex: '1 1 auto',
        maxWidth: !!maxAdWidth && ((typeof maxAdWidth === 'number' && `${maxAdWidth}px`) || maxAdWidth)
    },
    '.SlotContainer': {
        '&_container': {
            position: 'relative',
            width: '100%',
            height: '100%'
        },
        '&_content': {
            pointerEvents: 'none',
            position: 'relative',
            zIndex: 2,
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            flexDirection: inColumn ? 'column' : 'row',
            '.adunitContainer': {
                pointerEvents: 'all'
            }
        },
        '&_placeholder': {
            display: minHeightXs ? 'block' : 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
            width: '100%',
            height: '100%',
            [theme.breakpoints.up('md')]: {
                display: minHeightMd ? 'block' : 'none'
            }
        },
        '&_title': {
            display: minHeightXs ? 'block' : 'none',
            flex: '0 0 auto',
            textAlign: 'center',
            fontStyle: 'italic',
            fontSize: toRem(14),
            lineHeight: toRem(30),
            color: '#969696', // @TODO: add this color to theme
            [theme.breakpoints.up('md')]: {
                display: minHeightMd ? 'block' : 'none'
            }
        }
    }
})

const StyledComponent = createStyledComponent({ tag: 'div', styles })

export default StyledComponent
