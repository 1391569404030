import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Button } from '../../Button'
import { Icon } from '../../Icon'
import styles from './PaginationButton.style'

const PaginationButtonStyled = styled.div(props => ({ ...styles(props) }))

function PaginationButton({ icon, rotate, href, ...rest }) {
    if (!icon) {
        return null
    }

    return (
        <PaginationButtonStyled {...rest}>
            <Button href={href} iconComponent={<Icon icon={icon} rotate={rotate} color="#ffffff" />} />
        </PaginationButtonStyled>
    )
}

PaginationButton.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.shape({})]),
    rotate: PropTypes.number,
    href: PropTypes.string
}

PaginationButton.defaultProps = {
    icon: undefined,
    rotate: 0,
    href: undefined
}

export default PaginationButton
