import { useMemo } from 'react'

import { useAdManifest } from '../../context/ads/manifest.context'
import { getAdZoneKey } from '../../helpers/ads'
import { checkConfig } from '../../helpers/ads/checkConfig'
import useDeviceType from '../../hooks/layout/useDeviceType'
import { AdPositionDevice, AdPositionLoaded } from '../../types/ads/Ad.interface'

const defaultFalse = { enabled: false, message: 'no config' }
export type AdZoneNameInput = string | { mobile: string; desktop: string }
export type AdZoneName = AdZoneNameInput | AdZoneNameInput[]

const parseAdZoneConfig = (dfpZones: AdPositionDevice, adZoneName: AdZoneNameInput) => {
    const parsedAdZoneName = typeof adZoneName === 'string' ? { mobile: adZoneName, desktop: adZoneName } : adZoneName
    const mobileZoneName = getAdZoneKey('M', parsedAdZoneName?.mobile)
    const desktopZoneName = getAdZoneKey('D', parsedAdZoneName?.desktop)
    const output = {
        mobile: dfpZones?.[mobileZoneName] || defaultFalse,
        desktop: dfpZones?.[desktopZoneName] || defaultFalse,
        mobileAdZone: mobileZoneName,
        desktopAdZone: desktopZoneName
    }
    return output
}

type MergedSlotDeviceConfigs = ReturnType<typeof parseAdZoneConfig>

const toArray = <T>(value: T | T[]): T[] => (Array.isArray(value) ? value : [value])

const parseAdZoneConfigs = (
    adZoneNames: AdZoneName,
    adManifest: any
): { mobile: any; desktop: any; mobileAdZone: string; desktopAdZone: string }[] =>
    toArray(adZoneNames)?.map(adZoneName => parseAdZoneConfig(adManifest?.dfp, adZoneName))

const parseDeviceProp = (device: string | null) => {
    switch (device?.toLowerCase()) {
        case 'mobile':
        case 'm':
            return 'mobile'
        case 'desktop':
        case 'd':
            return 'desktop'
        default:
            return 'any'
    }
}
export type DeviceType = 'mobile' | 'desktop' | 'any' | null
export type DeviceTypeParsed = ReturnType<typeof parseDeviceProp>
const getDeviceConfig = (
    mergedConfig: MergedSlotDeviceConfigs,
    deviceType: DeviceType,
    mobileOnly = false,
    desktopOnly = false
): AdPositionLoaded | { enabled: boolean; message: string } => {
    const devicePropParsed = parseDeviceProp(deviceType)
    if (!mergedConfig) {
        return { enabled: false, message: 'no config in general' }
    }
    if (!deviceType) {
        return { enabled: true, message: 'no device yet' }
    }
    if (mobileOnly && devicePropParsed !== 'mobile') {
        return { enabled: false, message: 'mobile only' }
    }
    if (desktopOnly && devicePropParsed !== 'desktop') {
        return { enabled: false, message: 'desktop only' }
    }
    if (devicePropParsed !== 'any' && devicePropParsed !== deviceType) {
        return {
            enabled: false,
            message: `disabled via deviceProp on this resolution ${deviceType} !== ${devicePropParsed}`
        }
    }
    const deviceConfig = mergedConfig?.[deviceType]
    if (!deviceConfig) {
        return { enabled: false, message: 'no config found' }
    }
    deviceConfig.zoneName = mergedConfig?.[`${deviceType}AdZone`]
    return deviceConfig
}

const parseMergedSlotConfig = (
    slotConfig: MergedSlotDeviceConfigs,
    deviceType: DeviceType,
    deviceProp: DeviceType,
    mobileOnly = false,
    desktopOnly = false
) => {
    const devicePropParsed = parseDeviceProp(deviceProp)
    const canHaveMobile = !desktopOnly && (devicePropParsed === 'any' || devicePropParsed === 'mobile')
    const canHaveDesktop = !mobileOnly && (devicePropParsed === 'any' || devicePropParsed === 'desktop')
    const hasDesktopConfig = canHaveDesktop && checkConfig(slotConfig?.desktop)
    const hasMobileConfig = canHaveMobile && checkConfig(slotConfig?.mobile)
    const config = getDeviceConfig(slotConfig, deviceType, mobileOnly, desktopOnly)
    return {
        config,
        hasMobileConfig,
        hasDesktopConfig,
        ...slotConfig,
        desktop: hasDesktopConfig ? slotConfig?.desktop : { enabled: false, message: 'no desktop config, or disabled' },
        mobile: hasMobileConfig ? slotConfig?.mobile : { enabled: false, message: 'no mobile config, or disabled' },
        isValid: hasMobileConfig || hasDesktopConfig
    } as {
        config: AdPositionLoaded | { enabled: boolean; message: string }
        hasMobileConfig: boolean
        hasDesktopConfig: boolean
        desktop: AdPositionLoaded | { enabled: boolean; message: string }
        mobile: AdPositionLoaded | { enabled: boolean; message: string }
        isValid: boolean
        mobileAdZone: string
        desktopAdZone: string
    }
}

export type FullSlotConfig = ReturnType<typeof parseMergedSlotConfig>

export const useSlotConfigs = (
    positions: AdZoneNameInput | AdZoneNameInput[],
    deviceProp: DeviceType,
    mobileOnly: boolean,
    desktopOnly: boolean
) => {
    const adManifest = useAdManifest()
    const [deviceType] = useDeviceType()
    return useMemo(
        () =>
            parseAdZoneConfigs(positions, adManifest).map(config =>
                parseMergedSlotConfig(config, deviceType, deviceProp, mobileOnly, desktopOnly)
            ),
        [positions, deviceType, adManifest, mobileOnly, desktopOnly]
    )
}
