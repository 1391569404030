/* eslint-disable react/prop-types */
import { useAmp } from 'next/amp'

import { Row } from '@hmn/rtl-web-core/components/layout'

import AdSlot from '@hmn/rtl-zena-ui/components/Ad/ZenaSlot.component'

function SingleSlot({ config }) {
    return config ? <AdSlot {...config} /> : null
}
function OutOfPageAdSlots({ config }) {
    const isAmp = useAmp()

    return (
        !isAmp && (
            <Row variant="zeroDimensions">
                <SingleSlot config={config?.outOfPage1} />
                {/* <SingleSlot config={config?.outOfPage2} /> */}
                <SingleSlot config={config?.outOfPage3} />
                <SingleSlot config={config?.anchor} />
            </Row>
        )
    )
}

export default OutOfPageAdSlots
