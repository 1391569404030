import { useMemo } from 'react'

const withAnimationFrame = <Callback extends (...props: any[]) => any, ForwardArguments extends boolean = true>(
    callback: Callback,
    forwardArguments: ForwardArguments = true as ForwardArguments
): ForwardArguments extends true ? (...args: Parameters<Callback>) => number : () => number => {
    if (forwardArguments) return (...args: Parameters<Callback>) => requestAnimationFrame(() => callback(...args))
    return () => requestAnimationFrame(() => callback())
}
export const useAnimationFrameCallback = <
    Callback extends (...props: any[]) => any,
    ForwardArguments extends boolean = true
>(
    callback: Callback,
    forwardArguments: ForwardArguments = true as ForwardArguments
) =>
    useMemo(
        () => withAnimationFrame<Callback, ForwardArguments>(callback, forwardArguments),
        [callback, forwardArguments]
    )
